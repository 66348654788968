import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/index/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/list',
    name: 'List',
    component: () => import('../views/list/index.vue')
  },
  {
    path: '/exam',
    name: 'Exam',
    component: () => import('../views/exam/index.vue')
  },
  {
    path: '/details',
    name: 'Details',
    component: () => import('../views/details/index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('../views/login/forget.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register/index.vue')
  },
  {
    path: '/record',
    name: 'Record',
    component: () => import('../views/user/record.vue')
  },
  {
    path: '/annal',
    name: 'Annal',
    component: () => import('../views/user/annal.vue')
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('../views/user/order.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/user/index.vue')
  },
  {
    path: '/password',
    name: 'Password',
    component: () => import('../views/user/password.vue')
  },
  {
    path: '/apply',
    name: 'Apply',
    component: () => import('../views/user/apply.vue')
  },
  {
    path: '/introduce',
    name: 'Introduce',
    component: () => import('../views/introduce/index.vue')
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
