<template>
  <div>
    <nav class="navbar navbar-default navbar-fixed-top header">
      <div class="container">
        <div class="navbar-header">
          <div class="rightse navbar-toggle collapsed" v-if="!isShow">
            <el-dropdown>
              <div class="rights-item">
                <img src="../assets/images/examine_head.png" alt="" />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  ><div @click="goNext('/User')">
                    个人信息
                  </div></el-dropdown-item
                >
                <el-dropdown-item>
                  <div @click="goNext('/Record')">听课记录</div>
                </el-dropdown-item>
                <el-dropdown-item
                  ><div @click="goNext('/Annal')">
                    考试记录
                  </div></el-dropdown-item
                >
                <el-dropdown-item
                  ><div @click="goNext('/Order')">
                    订单管理
                  </div></el-dropdown-item
                >
                <el-dropdown-item
                  ><div @click="goNext('/Password')">
                    修改密码
                  </div></el-dropdown-item
                >
                <el-dropdown-item
                  ><div @click="goNext('/Apply')">
                    申请取证
                  </div></el-dropdown-item
                >
                <el-dropdown-item divided>
                  <div @click="quit">退出账号</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <button type="button" class="navbar-toggle collapsed" @click="btnTab">
            <span class="sr-only">实验楼</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <div class="navbar-brand" @click="goNext('/')">
            <img src="@/assets/logo.png" />
          </div>
        </div>
        <div class="collapse navbar-collapse" :class="{ in: isBtn }">
          <ul class="nav navbar-nav">
            <li><a class="nav-item" @click="goNext('/')">首页</a></li>
            <li><a class="nav-item" @click="goNext('/List')">网络课程</a></li>
            <li><a class="nav-item" @click="goIntroduce(1)">公司介绍</a></li>
            <li><a class="nav-item" @click="goIntroduce(2)">特种作业</a></li>
            <li><a class="nav-item" @click="goIntroduce(3)">安全咨询</a></li>
            <li><a class="nav-item" @click="goIntroduce(4)">实训场地</a></li>
            <li><a class="nav-item" @click="goIntroduce(5)">职业技能</a></li>
            <li><a class="nav-item" @click="goIntroduce(6)">就业推荐</a></li>
            <li><a class="nav-item" @click="goIntroduce(7)">材料资源</a></li>
          </ul>

          <div class="navbar-right btns">
            <div class="right" v-if="isShow">
              <div class="login" @click="Login">登录</div>
              <div class="login-btn" @click="Register">注册</div>
            </div>
            <div class="rights" v-if="!isShow && !isBtn">
              <el-dropdown>
                <div class="rights-item">
                  <img src="../assets/images/examine_head.png" alt="" />
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    ><div @click="goNext('/User')">
                      个人信息
                    </div></el-dropdown-item
                  >
                  <el-dropdown-item>
                    <div @click="goNext('/Record')">听课记录</div>
                  </el-dropdown-item>
                  <el-dropdown-item
                    ><div @click="goNext('/Annal')">
                      考试记录
                    </div></el-dropdown-item
                  >
                  <el-dropdown-item
                    ><div @click="goNext('/Order')">
                      订单管理
                    </div></el-dropdown-item
                  >
                  <el-dropdown-item
                    ><div @click="goNext('/Password')">
                      修改密码
                    </div></el-dropdown-item
                  >
                  <el-dropdown-item
                    ><div @click="goNext('/Apply')">
                      申请取证
                    </div></el-dropdown-item
                  >
                  <el-dropdown-item divided>
                    <div @click="quit">退出账号</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
export default {
  name: "HeaderView",
  data() {
    return { isShow: true, isBtn: false };
  },
  mounted() {
    if (localStorage.getItem("GFtoken") == null) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  },
  methods: {
    Login() {
      if (this.$route.path != "/Login") {
        this.$router.replace("Login");
      }
    },
    Register() {
      if (this.$route.path != "/register") {
        this.$router.push("Register");
      }
    },
    goNext(index) {
      if (this.$route.path != index) {
        this.$router.push({
          path: index,
        });
      }
    },
    goIntroduce(index) {
      this.$router.push({
        path: "Introduce",
        query: { type: index },
      });
    },
    quit() {
      this.$router.push("Login");
      localStorage.removeItem("GFtoken");
    },
    btnTab() {
      console.log(this.isBtn);
      this.isBtn = !this.isBtn;
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .navbar-brand {
    width: 70px !important;
  }
}
.header {
  background-color: #ffffff;
}
.rightse {
  padding: 0;
  margin-right: 15px;
  margin-top: 8px;
  margin-bottom: 8px;
  border: none;
  border-radius: 4px;
  &:hover {
    background-color: #ffffff !important;
  }
  .rights-item {
    height: 34px;
    width: 34px;
    background-color: pink;
    border-radius: 50%;
    cursor: pointer;
  }
}
.navbar-right {
  .right {
    display: flex;
    align-items: center;
    padding-top: 8px;

    .login {
      height: 34px;
      color: hsla(0, 2%, 9%, 0.5);
      // background-color: #191e29;
      border: 1px solid hsla(0, 2%, 9%, 0.5);
      border-radius: 4px;
      padding: 0 12px;
      font-size: 14px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: #f1efef;
      }
    }

    .login-btn {
      height: 34px;
      color: #fff;
      background-color: #11aa8c;
      border: 1px solid #11aa8c;
      border-radius: 4px;
      padding: 0 12px;
      font-size: 14px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 10px;

      &:hover {
        background-color: #10be9d;
      }
    }
  }
}
</style>
