import axios from 'axios'
import router from '@/router'

/** 移除授权信息 */
export function removeAuth() {
  return new Promise((resolve, reject) => {
    localStorage.removeItem('GFtoken')
    router.replace("Login");
    resolve(true)
  })
}
