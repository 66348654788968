import request from '@/utils/request'
// 登录
export function login(data) {
  return request({
    url: 'login',
    method: 'post',
    data: data
  })
}
// 注册
export function register(data) {
  return request({
    url: '/register/student',
    method: 'post',
    data: data
  })
}
// 忘记密码
export function forgetPass(data) {
  return request({
    url: '/forgetPass',
    method: 'put',
    data: data
  })
}
// 验证码
export function sendSms(data) {
  console.log(data)
  return request({
    url: '/register/sendSms' + '/' + data,
    method: 'get'
  })
}
// 验证码
export function navigation(data) {
  console.log(data)
  return request({
    url: '/index/navigation' + '/' + data,
    method: 'get'
  })
}
// 验证码 忘记密码
export function forgetSms(data) {
  console.log(data)
  return request({
    url: '/forgetSms' + '/' + data,
    method: 'get'
  })
}
// /register/sendSms/{phone}
// 首页
export function index(data) {
  return request({
    url: '/index/courseIndex',
    method: 'get',
    data: data
  })
}
