<template>
  <div>
    <header-view></header-view>
    <div class="top">
      <div class="top-conter">
        <div class="top-img">
          <!-- <img src="../../assets/images/container-title-2.png" alt="" /> -->
          宣传安全知识 传播安全文化
        </div>
        <div class="btn-box">
          <div class="btn" @click="goList">进入课程</div>
        </div>
        <!-- <div class="right">
					<el-form   :model="formLabelAlign">
					  <el-form-item>
					    <el-input placeholder="手机号" v-model="formLabelAlign.name"></el-input>
					  </el-form-item>
					  <el-form-item>
					    <el-input placeholder="密码" v-model="formLabelAlign.region"></el-input>
					  </el-form-item>
					  <el-form-item>
					    <el-input placeholder="短信验证码" v-model="formLabelAlign.type"></el-input>asdad
					  </el-form-item>
					</el-form>
				</div> -->
      </div>
    </div>
    <div class="content">
      <div class="nav">
        <div class="nav-item" @click="goList">
          <img
            class="nav-img"
            src="../../assets/images/introduceContainer1.png"
          />
          <div class="nav-title">特种作业培训</div>
        </div>
        <div class="nav-item" @click="goList">
          <img
            class="nav-img"
            src="../../assets/images/introduceContainer2.png"
          />
          <div class="nav-title">安全生产技术咨询</div>
        </div>
        <div class="nav-item" @click="goList">
          <img
            class="nav-img"
            src="../../assets/images/introduceContainer3.png"
          />
          <div class="nav-title">职业在线培训</div>
        </div>
        <div class="nav-item" @click="goList">
          <img
            class="nav-img"
            src="../../assets/images/introduceContainer4.png"
          />
          <div class="nav-title">人力资源服务</div>
        </div>
      </div>
      <div class="title">
        <div class="line"></div>
        <div class="title-t">学习路径</div>
        <div class="line"></div>
      </div>
      <div class="tab">
        <div class="tab-item" @click="goList">
          <div class="tab-img">
            <img src="../../assets/images/tab1.png" />
          </div>
          <div class="tab-title">
            <div class="big">取证课程</div>
            <div class="sm">{{ cardCourseList.length }}门课程</div>
          </div>
        </div>
        <div class="tab-item" @click="goList">
          <div class="tab-img">
            <img src="../../assets/images/tab2.png" />
          </div>
          <div class="tab-title">
            <div class="big">培训课程</div>
            <div class="sm">{{ trainCourseList.length }}门课程</div>
          </div>
        </div>
        <div class="tab-item" @click="goList">
          <div class="tab-img">
            <img src="../../assets/images/tab3.png" />
          </div>
          <div class="tab-title">
            <div class="big">免费课程</div>
            <div class="sm">{{ freeCourseList.length }}门课程</div>
          </div>
        </div>
      </div>
      <div v-if="cardCourseList.length != 0">
        <div class="title">
          <div class="line"></div>
          <div class="title-t">取证课程</div>
          <div class="line"></div>
        </div>
        <div class="course" v-loading="cardLoading">
          <div
            class="course-item"
            v-for="(item, index) in cardCourseList"
            :key="index"
            @click="goDetails(item.id)"
          >
            <div class="course-img">
              <img :src="item.icon" />
            </div>
            <div class="course-t">
              <div class="txt">{{ item.title }}</div>
              <div class="prc">
                {{ item.isFree == 1 ? "免费" : "￥" + item.price }}
              </div>
            </div>
          </div>
        </div>
        <div class="more" @click="goList">查看更多 ></div>
      </div>
      <div v-if="trainCourseList.length != 0">
        <div class="title">
          <div class="line"></div>
          <div class="title-t">培训课程</div>
          <div class="line"></div>
        </div>
        <div class="course" v-loading="cardLoading">
          <div
            class="course-item"
            v-for="(item, index) in trainCourseList"
            :key="index"
            @click="goDetails(item.id)"
          >
            <div class="course-img">
              <img :src="item.icon" />
            </div>
            <div class="course-t">
              <div class="txt">{{ item.title }}</div>
              <div class="prc">
                {{ item.isFree == 1 ? "免费" : "￥" + item.price }}
              </div>
            </div>
          </div>
        </div>
        <div class="more" @click="goList">查看更多 ></div>
      </div>
      <div v-if="freeCourseList.length != 0">
        <div class="title">
          <div class="line"></div>
          <div class="title-t">免费课程</div>
          <div class="line"></div>
        </div>
        <div class="course" v-loading="cardLoading">
          <div
            class="course-item"
            v-for="(item, index) in freeCourseList"
            :key="index"
            @click="goDetails(item.id)"
          >
            <div class="course-img">
              <img :src="item.icon" />
            </div>
            <div class="course-t">
              <div class="txt">{{ item.title }}</div>
              <div class="prc">
                {{ item.isFree == 1 ? "免费" : "￥" + item.price }}
              </div>
            </div>
          </div>
        </div>
        <div class="more" @click="goList">查看更多 ></div>
      </div>
    </div>
    <footer-view></footer-view>
  </div>
</template>

<script>
import HeaderView from "@/components/HeaderView";
import FooterView from "@/components/FooterView";
import { index } from "@/api/index";
export default {
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    return {
      labelPosition: "right",
      formLabelAlign: {
        name: "",
        region: "",
        type: "",
      },
      cardCourseList: [],
      freeCourseList: [],
      trainCourseList: [],
      cardLoading: false,
    };
  },
  created() {
    this.school();
  },
  mounted() {},
  methods: {
    goList() {
      this.$router.push("List");
    },
    // 列表
    school() {
      this.cardLoading = false;
      index()
        .then((res) => {
          this.cardCourseList = res.data.cardCourseList;
          this.freeCourseList = res.data.freeCourseList;
          this.trainCourseList = res.data.trainCourseList;
          this.cardLoading = false;
        })
        .catch(() => {
          this.cardLoading = false;
        });
    },
    goDetails(id) {
      this.$router.push({
        path: `Details`,
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .top-img {
    font-size: 30px !important;
  }
  .nav {
    flex-direction: column;
    align-items: center;
    .nav-item {
      margin-bottom: 10px;
    }
  }
  .tab {
    flex-direction: column;
    align-items: center;
    .tab-item {
      margin-bottom: 10px;
      width: 80% !important;
    }
  }
  .title {
    margin: 32px 0 !important;

    .line {
      width: 80px !important;
    }

    .title-t {
      font-size: 26px !important;
      margin: 0 20px !important;
    }
  }
  .course {
    flex-direction: column;
    align-items: center;
    .course-item {
      margin-bottom: 10px;
      margin-right: 0 !important;
    }
  }
  .top-conter {
    padding-top: 200px !important;
  }
  .content{
     width: 100% !important;
  }
}
.top {
  background: url("../../assets/images/homepage-bg.jpg");
  background-size: cover;
  backgtound-repeat: no-repeat;

  .top-conter {
    // max-width: 1170px;
    // margin-right: auto;
    // margin-left: auto;
    // height: 612px;
    padding-top: 295px;
    box-sizing: border-box;

    .top-img {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 50px;
      // color: #ffffff;
      // img {
      //   width: 583px;
      //   height: 124px;
      // }
    }

    .btn-box {
      display: flex;
      justify-content: center;
      .btn {
        color: #fff;
        background-color: #11aa8c;
        border-color: #11aa8c;
        margin-bottom: 30px;
        font-size: 20px;
        border-radius: 4px;
        width: 154px;
        padding: 12px 36px;
        margin-top: 75px;
        cursor: pointer;

        &:hover {
          color: #fff;
          background-color: #28c5a6;
        }
      }
    }

    // .right{
    // 	width:41%;
    // 	flex-shrink: 0;
    // 	padding: 0 50px;
    // 	box-sizing: border-box;
    // }
  }
}

.content {
  width: 1170px;
  margin-right: auto;
  margin-left: auto;
  .nav {
    display: flex;
    justify-content: space-around;
    padding: 35px 0;
    box-sizing: border-box;

    .nav-item {
      cursor: pointer;
      .nav-img {
        height: 168px;
        width: 168px;
        border-radius: 50%;
      }

      .nav-title {
        font-size: 16px;
        color: #6a849d;
        text-align: center;
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 64px 0;

    .line {
      width: 136px;
      height: 1px;
      background-color: #777;
    }

    .title-t {
      color: #777;
      font-size: 30px;
      margin: 0 40px;
    }
  }

  .tab {
    display: flex;
    justify-content: space-between;

    .tab-item {
      cursor: pointer;
      width: 32%;
      height: 100px;
      box-shadow: 0 0 3px #ccc;
      display: flex;

      .tab-img {
        height: 100px;
        width: 100px;
      }

      .tab-title {
        margin-left: 20px;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: flex-start;

        .big {
          color: #565656;
          font-size: 18px;
        }

        .sm {
          color: #9b9da2;
          font-size: 14px;
        }
      }
    }
  }

  .course {
    display: flex;

    .course-item {
      width: 255px;
      background: #fff;
      box-shadow: 0 2px 2px 0 #ccc;
      flex-shrink: 0;
      margin-right: 50px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }

      .course-img {
        width: 255px;
        height: 140px;
      }

      .course-t {
        padding: 0 20px;
        box-sizing: border-box;

        .txt {
          color: #4c5157;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 20px;
          display: flex;
        }

        .prc {
          color: #9b9da2;
          font-size: 12px;
          padding: 20px 0;
          box-sizing: border-box;
          display: flex;
        }
      }
    }
  }

  .more {
    color: #787878;
    font-size: 14px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 54px;
    align-items: flex-end;
    padding-bottom: 20px;
    box-sizing: border-box;
    cursor: pointer;
  }
}
</style>
