<template>
	<div>
		<div class="footer">
			京ICP备15029299号
		</div>
		<div style="width:300px;margin:0 auto; padding:20px 0;">
     		<a target="_blank" href=" " style="display:inline-block;text-decoration:none;height:30px;line-height:30px;">
			<img style="width:30px;high:30px;float:left;" src="../assets/images/p.png"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备 11011302005456号</p >
			</a>
    	</div>
	</div>
</template>

<script>
	export default {
		name:'FooterView',
		data() {
			return {
			};
		},
		methods:{

		}
	}
</script>

<style lang="scss" scoped>
	.footer {
		height: 50px;
		background: #ffffff;
		border-top: 1px solid #f1efef;
		width: 100%;
		// min-width: 1170px;
		    font-size: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #777;
	}
</style>
